import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet molt carnós amb un capell de 3 a 9 cm de diàmetre primer acampanat per passar a obert i aplanat. És de color ocre clar. Les làmines són de color rosa crema brut, no gaire amples i el peu és ample i no gaire llarg, amb la base més gruixuda cobert de fibril·les o esquames del mateix color crema que el peu. La carn pot fer olor a xocolata o fruita que no sempre s’aprecia. Les espores són marró groguenques en massa, berrugoses, en forma d’ametla, de 9-11 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      